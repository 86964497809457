import { merge } from 'theme-ui';
import { tailwind } from '@theme-ui/presets';
// lodash.merge will deeply merge custom values with the
// blog theme's defaults
// console.log('------- tailwind --------->', tailwind);
export default merge(tailwind, {
  layout: { container: { maxWidth: '100vw' } },
  colors: {
    text: '#222',
    primary: '#a40000',
    dark: '#091d33',
    link: ' #34699a',
  },
  styles: {
    h1: {
      fontFamily: 'Roboto Slab',
      fontWeight: '700',
      color: 'primary',
    },
    h2: {
      fontFamily: 'Roboto Slab',
      fontWeight: '700',
      color: 'primary',
    },
    h3: {
      fontFamily: 'Roboto Slab',
      fontWeight: '400',
    },
  },
});
