
import { ThemeProvider } from '@emotion/react';
import React from 'react';
import theme from './src/gatsby-plugin-theme-ui/index';

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

const {
  registerLinkResolver,
} = require("@prismicio/gatsby-source-prismic-graphql");
const { linkResolver } = require("./src/utils/linkResolver");

registerLinkResolver(linkResolver);
